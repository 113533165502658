'use client'
import { constants } from '@/utils/global'
import Image from 'next/image'
import { Suspense, useState } from 'react'

const { NO_IMG } = constants

const CardImage = ({ urlThumb, imgalt, cardWidth, hostname, imgTitle = ``, bike = false, containt = `object-cover` }) => {
  const [ imgError, setImgError ] = useState(false)
  const { mobile, styles } = hostname
  const handleError = () => {
    setImgError(true)
  }

  return (
    <Suspense>
      <div className={`${styles?.cardBorder ?? `card-rounded`} size-full flex items-center justify-center ${!mobile && `contrastImg`}`}>
        {!bike ? (
          <Image
            alt={imgalt}
            title={imgTitle}
            draggable="false"
            className={`${styles?.cardImage ?? `image-rounded`} p-4 object-contain ${imgError ? `size-24` : `size-full`}`}
            src={imgError ? NO_IMG : urlThumb}
            width={mobile ? 164 : cardWidth}
            height={mobile ? 164 : cardWidth}
            onError={handleError}
            loading="lazy"
            decoding="async"
            fetchPriority="high"
            unoptimized={false}
          />
        ) : (
          <Image
            alt={imgalt}
            title={imgTitle}
            draggable="false"
            className={`rounded-xl ${containt} ${imgError ? `size-24` : `size-full`}`}
            src={imgError ? NO_IMG : urlThumb}
            width={mobile ? 164 : cardWidth}
            height={mobile ? 164 : cardWidth}
            onError={handleError}
            loading="lazy"
            fetchPriority="high"
            decoding="async"
            unoptimized={false}
          />
        )}
      </div>
    </Suspense>
  )
}

export default CardImage
