'use client'
import { fixTwoDecimal } from "@/utils/functions"
import { shippingCost } from "@/utils/functions/cart"
import { useEffect, useMemo, useState } from "react"

export const ShipCost = props => {
  const {
    pageTexts, hostname, totalPrice, currency, setShipCost
  } = props
  const [ cost, setCost ] = useState(undefined)
  const [ load, setLoad ] = useState(true)
  const basket = null
  const { ipInfo, colors } = hostname

  const getShip = async() => {
    try {
      cost === undefined && await setLoad(true)
      const result = await shippingCost({
        total: parseFloat(totalPrice),
        country: `ES`,
        zip: ipInfo.zip,
        tariffV: hostname?.discount_tariff_id,
        ship: ipInfo.ship,
        address: basket?.shipping_address_id || `0`
      })
      const shipCost = await result
      if (shipCost.shipCost !== undefined) {
        await setLoad(false)
      }
      await setCost(shipCost.shipCost)
      await setShipCost && setShipCost(shipCost.shipCost)
    } catch (e) {
      await setCost(0)
      await setShipCost && setShipCost(0)
    }
  }

  useEffect(() => {
    getShip()
  }, [totalPrice])

  return !load && <div className="mt-1">
    <p className="bg-white uppercase font-bold" style={{ color: cost ? colors.darkGrey2 : colors.GreenAv }}>
      {cost === 0 ? pageTexts.free_shipping : `${pageTexts.shipping_cost} ${fixTwoDecimal(cost ?? 0 * currency.exchange)}${currency.symbol}`}
    </p>
  </div>
}
