/* eslint-disable no-mixed-operators */
'use client'
import { CatalogueContext } from "@/context"
import { types } from "@/types"
import { getControlledSections, handleKeyRandom } from "@/utils/functions"
import { useContext, useId, useMemo, useState } from "react"
import { DynamicFilter, MoreFilter, OrderFilter, PriceFilter, StaticFilter, StaticFilterCat } from "../global"

export const DesktopFilters = props => {
  const { hostname, filters, category, search, mobile = false, fill, motovo, brandAttr, specialList, colors, prodList } = props
  const { business, webPages } = hostname
  const showSection = getControlledSections(webPages, `filters`)
  const { brands: tmpBrands, categories } = prodList
  const idRef = useId()
  const [ more, setMore ] = useState(false)
  const { info } = useContext(CatalogueContext)
  const active = useMemo(() => (category ? 2 : 1) + filters?.length || 0, [filters])
  const divMobile = { mobile: `true`, width: `97%` }
  const show = useMemo(() => {
    const maxIndex = category ? 2 : 3
    const tempArr = filters?.map((filter, i) => ({
      ...filter,
      show: active >= business.filterShow && i <= maxIndex
    }))
    return active >= business.filterShow ? tempArr : filters
  }, [filters])

  const newBrands = useMemo(() => brandAttr?.map(x => {
    return tmpBrands.includes(x.brand_id) && x
  }).filter(x => x !== false) || [], [ brandAttr, tmpBrands ])

  const newCategories = useMemo(() => specialList?.map(x => {
    return categories.includes(x.info.id) && x
  }).filter(x => x !== false) || [], [ specialList, categories ])

  return (
    <div className="tablet:flex tablet:gap-10">
      <div className="tablet:flex tablet:flex-wrap mt-1 divide-y tablet:divide-y-0">
        { specialList?.length > 0 && !!showSection?.catFilter &&
          <div className="mr-1 mb-1">
            <StaticFilterCat
              {...props}
              filters={info?.pathFilter?.filter(x => x.type === types.catalog.category) || []}
              {...{ options: newCategories, mobile, fill, colors }}/>
          </div>
        }

        {category && !motovo && !!showSection?.brandFilter && info.id !== 234 && (
          <div className="mr-1 mb-1">
            <StaticFilter
              {...props}
              filters={info?.pathFilter?.filter(x => x.type === types.catalog.brand) || []}
              {...{ options: newBrands, mobile, fill, colors }}
            />
          </div>
        )}
        <div className="mr-1 mb-1">
          <PriceFilter
            {...props}
            filters={info?.pathFilter?.filter(x => x.type === types.catalog.priceRange) || []}
            {...{ mobile, fill }} />
        </div>

        {category && !motovo && info.id === 234 && (
          <div className="mr-1 mb-1">
            <StaticFilter
              {...props}
              filters={info.pathFilter.filter(x => x.type === types.catalog.brand) || []}
              {...{ options: brandAttr, mobile, fill, colors }}
            />
          </div>
        )}
        {!!show && filters?.map((filter, idx) => {
          const tmpAttr = filter.attributes.map(x => prodList.attr.includes(x.id) ? x : null).filter(x => x !== null)
          const tmpFilBrand = info?.pathFilter?.filter(x => x.type === types.catalog.brand).map(x => x.parentId).
            flat()
          return !(filter.meta_moto === 0 && !(tmpAttr.length > 0)) && (
            <div key={handleKeyRandom(idRef, idx)} className="mr-1 mb-1">
              <DynamicFilter
                {...props}
                show={mobile || more || show?.some(x => x.attribute_id === filter.attribute_id && x.show)}
                filters={info?.pathFilter?.filter(x => x.type === types.catalog.product && x.parentId === filter.attribute_id)}
                disabled={(filter.meta_moto === 0 && !(tmpAttr.length > 0) || filter.meta_moto === 1 && tmpFilBrand.length < 1)}
                {...{ filter, options: category &&
              filter?.meta_moto === 1 ? filter.attributes.filter(x => tmpFilBrand.includes(x.brand_id)) :
                  filter.attributes, attr: prodList.attr, mobile, fill, divMobile, brands: brandAttr?.map(x => x.brand_id).flat() || []}}
              />
            </div>
          )
        })}
      </div>
      <div className="hidden tablet:flex whitespace-nowrap ml-auto mt-1 gap-1 h-auto">
        {active > 5 && <MoreFilter mobile={false} {...props} {...{ setMore, more }} />}
        <OrderFilter {...props} mobile={false} filters={info.pathFilter?.filter(x => x.type === types.catalog.order.order) || []} />
      </div>
    </div>
  )
}
