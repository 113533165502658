import { TWButton } from "@/components/tailwind/Button"
import { CatalogueContext } from "@/context"
import { useRouter } from "next/navigation"
import { useContext } from "react"

export const BottomDrawer = props => {
  const router = useRouter()
  const { setOpen, colors, result, pageTexts } = props
  const { info, handlePathFilter, handleLoading } = useContext(CatalogueContext)
  const handleRemove = async e => {
    await e.preventDefault()
    await handleLoading(true)
    router.push(info.cleanPath)
    await handlePathFilter([])
  }
  return (
    <div className="flex justify-around py-4">
      <TWButton
        {...{ colors }}
        onClick={handleRemove}
        disabled={info.pathFilter?.length === 0}
        className="text-[14px] h-8 w-[35%]"
      >
        {pageTexts?.remove_filters ?? ``}
      </TWButton>
      <TWButton
        {...{ colors }}
        onClick={() => setOpen(false)}
        className="text-[14px] h-8 w-[55%]"
      >
        {`${pageTexts?.show_results ?? ``} (${result ?? 0})`}
      </TWButton>
    </div>
  )
}
