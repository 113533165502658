'use client'
import { types } from "@/types"
import { useEffect, useReducer } from "react"
import { initObj } from "./Obj"
import { getLabels } from "@/app/elastic/tools"
import { LabelsContext } from "./LabelsContext"
import { labelsReducer } from "./labelsReducer"

export const LabelsProvider = ({ children, ...props }) => {
  const { hostname } = props
  const { platformId, langId: languageId, business: { abbrElastic }} = hostname
  const [ state, dispatch ] = useReducer(labelsReducer, initObj)

  const handleLabels = prop => {
    dispatch({ type: types.tools.labels, payload: prop })
  }
  const executeGetLabels = async() => {
    const { result } = await getLabels({ platformId, languageId, abbrElastic })
    await handleLabels(result)
  }
  useEffect(() => {
    executeGetLabels()
  }, [])

  return (<LabelsContext.Provider
    value={{
      ...state
    }}>
    { children }
  </LabelsContext.Provider>)
}
