'use client'
import { ToolsContext } from "@/context/tools/tools"
import { useApi } from "@/hooks/apicall"
import { types } from "@/types"
import { constants } from "@/utils/global"
import IconsSVG from "@/utils/icons/IconsSVG"
import parse from "html-react-parser"
import { useContext, useEffect, useState } from "react"

export const WishList = props => {
  const {
    product, hostname, web, wh = 17, prodFile = false, small
  } = props
  const { login, mobile } = hostname
  const { tools: { colors }} = useContext(ToolsContext)
  const [ wish, setWish ] = useState(false)
  const { dfltApiCall } = useApi()

  const handleWish = async e => {
    e.stopPropagation()
    e.preventDefault()
    const resp = await dfltApiCall({
      uid: login?.user?.user_id || null,
      productId: product?.prod_id || product?.id
    }, null, constants.URL.PRODUCT_WISH)
    if (resp?.wish) {
      await setWish(resp.wish)
    }
  }

  useEffect(() => {
    setWish(!!product?.wishlist?.id)
  }, [product])

  return (
    <span id="wishlist-button" onClick={handleWish}
      className={`bottom-3 right-2 absolute z-50 text-center hover:contrast-0 ${wish ? `` : `contrast-[0.7]`}`}>
      <IconsSVG name="wishlist" path={`/img/product/${types.wishlistIcons[wish ? `complet` : `perfil`]}`} size={wh} fillcolor={colors.main}/>
      {prodFile && (
        <p style={{ color: colors.darkGrey2 }} className="underline font-semibold m-0 pr-3 underline-offset-4">
          {parse(web?.links?.wishlist[wish ? `text2` : `text1`])}
        </p>
      )}
    </span>
  )
}
