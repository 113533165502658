'use client'
import { useMediaQuery } from "@mui/material"

export const useMediaQuerys = props => {
  const xsQuery = useMediaQuery(`(min-width:900px)`)
  const tablet = useMediaQuery(`(min-width:900px)`)
  const mdMenu = useMediaQuery(`(min-width:1200px)`)
  const xs = useMediaQuery(`(max-width:375px)`)
  const _500 = useMediaQuery(`(min-width:500px)`)
  const _615M = useMediaQuery(`(max-width:615px)`)
  const _650 = useMediaQuery(`(min-width:650px)`)
  const _790 = useMediaQuery(`(min-width:790px)`)
  const _900 = useMediaQuery(`(min-width:900px)`)
  const _999 = useMediaQuery(`(min-width:999.98px)`)
  const _1200M = useMediaQuery(`(max-width:1199.98px)`)
  const _1200 = useMediaQuery(`(min-width:1200px)`)
  const _1320 = useMediaQuery(`(min-width:1320px)`)
  const _1380 = useMediaQuery(`(min-width:1380px)`)
  const _1540 = useMediaQuery(`(min-width:1540px)`)
  const _1640M = useMediaQuery(`(max-width:1639.99px)`)
  const _1600 = useMediaQuery(`(min-width:1600px)`)
  const _1680 = useMediaQuery(`(min-width:1681px)`)

  return {
    xsQuery,
    tablet,
    mdMenu,
    xs,
    _500,
    _615M,
    _650,
    _790,
    _900,
    _999,
    _1200,
    _1200M,
    _1320,
    _1380,
    _1540,
    _1600,
    _1640M,
    _1680
  }
}
