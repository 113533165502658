import { apiCall } from "@/middleware/api"
import { encodeHashJS } from "@/middleware/api/secure"

export const useApi = () => {
  // TODO - implementar AbbortController controlando una lista de peticiones
  // const [ ctr, setCtr ] = useState()

  const dfltApiCall = async(
    data,
    setResponse,
    url,
    setLoading,
    domain = process.env.NEXT_PUBLIC_DOMAIN,
    api = process.env.NEXT_PUBLIC_URL_API
  ) => {
    // if (ctr) {
    //   await ctr.abort()
    // }
    setLoading && await setLoading(true)
    const encrypt = data || ``
    const sendData = {
      url,
      encrypt,
      type: data ? `POST` : `GET`,
      domain
    }
    const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
    const { error, message } = await apiCall(encriptedData, false, api)

    // await setCtr(controller)
    setResponse && await setResponse(message.data || message)
    setLoading && await setLoading(false)
    return message.data || message
  }

  const dfltApiCallFull = async(
    data,
    setResponse,
    url,
    setLoading,
    domain = process.env.NEXT_PUBLIC_DOMAIN,
    api = process.env.NEXT_PUBLIC_URL_API
  ) => {
    // if (ctr) {
    //   await ctr.abort()
    // }
    setLoading && await setLoading(true)
    const encrypt = data || ``
    const sendData = {
      url,
      encrypt,
      type: data ? `POST` : `GET`,
      domain
    }
    const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
    const { error, message } = await apiCall(encriptedData, false, api)

    // await setCtr(controller)
    setResponse && await setResponse(message)
    setLoading && await setLoading(false)
    return {
      error,
      message
    }
  }

  return {
    dfltApiCall,
    dfltApiCallFull
  }
}
