import { cmp } from "@/types"
import IconsSVG from "@/utils/icons/IconsSVG"

export const CouponList = props => {
  const {
    catalogue, mobile, plus, wh, coupons, ft
  } = props
  return (
    <span id="coupon-indicator"

      // className={`${mobile ? `top-[135px] left-[10px]` : `top-[226px] left-[12px]`} absolute z-50 text-center`}>
      className={`${mobile ? `top-[135px] left-[10px]` : `top-[180px] left-[12px]`} absolute z-50 text-center`}>
      {coupons && !catalogue && (
        <IconsSVG name={cmp.couponList} path={`/img/nav-icons/web-promotions.svg`} />
      )}
      <p style={{ fontSize: `${ft ?? 15}px` }} className="text-red-700 font-semibold m-0 pr-0">
        {/* // TODO - OBTENER CUPONES BBDD */}
        {/* {parse(web.coupons || ``)} */}
      </p>
    </span>
  )
}
