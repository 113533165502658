export const constructScript = (tag, name, data) => {
  'use client'
  const script = document.createElement(tag)
  script.id = name
  script.type = `application/ld+json`
  script.text = data
  document.head.appendChild(script)
}
export const removeScript = name => {
  const existingScript = document.getElementById(name)
  if (existingScript) {
    existingScript.remove()
  }
}
