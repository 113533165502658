import { TWButton } from "@/components/tailwind/Button"
import { useMediaQuerys } from "@/hooks/utils/useMediaQuerys"
import { types } from "@/types"
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md"
import { DropDownArrows } from "../../basket/subcomponent/components/subcomponents/DropDownArrows"
import { DrawerEdge } from "../drawer"

export const MoreFilter = ({ ...props }) => {
  const { more, setMore, filters, colors, prodList: { result }, pageTexts } = props
  const { tablet } = useMediaQuerys()
  return (
    <div>
      <TWButton
        {...{ colors }}
        type="dropdown"
        bg={colors.lightGrey}
        border={colors.lightGrey3}
        aria-controls={`more-info`}
        aria-expanded={`more-info`}
        aria-label="more-info"
        aria-haspopup=""
        onClick={() => setMore(!more)}
        className="w-full rounded-r-none tablet:rounded-r-full py-[2px] px-[10px] min-w-40"
      >
        <p className="hidden tablet:block">
          {more ? pageTexts?.less_filters ?? `` : pageTexts?.more_filters ?? `` }
        </p>
        <div className="block tablet:hidden">
          <MdFilterAlt className={`text-neutral-500 w-4 h-4`} />
        </div>
        <p className="block tablet:hidden">
          {pageTexts?.filter_by ?? ``}
        </p>
        <div className="hidden tablet:block">
          {!more ? <MdFilterAlt className={`text-neutral-600 -mr-1`} /> : <MdFilterAltOff className={`text-neutral-600 -mr-1`} />}
        </div>
        <div className="flex tablet:hidden">
          {filters?.length > 0 && (
            <div style={{ backgroundColor: colors.secondary }} className={`rounded-full h-2 w-2 self-center`}/>
          )}
          <DropDownArrows {...{ open: more }} className="text-neutral-600"/>
        </div>
      </TWButton>
      {!tablet && <div className="col-span-2">
        <DrawerEdge {...props} {...{ pageTexts, result, colors, open: more, setOpen: setMore, type: types.catalog.product, text: pageTexts?.filter_by ?? `` }}>
          {props.children}
        </DrawerEdge>
      </div>}
    </div>
  )
}
