import { cmp } from "@/types"
import {
  ClickAwayListener, Grow, Paper, Popper
} from "@mui/material"
import { useId } from "react"
export const PopperFilter = ({ ...props }) => {
  const { open, anchorRef, handleClose } = props
  const idRef = useId()
  return (
    <Popper open={open} anchorEl={anchorRef.current} transition={true}>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === `bottom` ? `center top` : `center bottom`,
          }}
        >
          <Paper cmptype={cmp.accButton} height={350} sx={{ width: `100%` }}>
            <ClickAwayListener onClickAway={handleClose}>
              <div id={idRef}>{props.children}</div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}
