'use client'

import { sendGTMEvent, sendGAEvent } from '@next/third-parties/google'
export const clearDL = () => {
  // if (window.dataLayer !== undefined && window.google_tag_manager !== undefined) {
  //   window.dataLayer.length = 0
  //   const gtmContainerReg = /GTM-/i

  //   for (const gtmKey of Object.keys(window?.google_tag_manager)) {
  //     if (gtmContainerReg.test(gtmKey) && window?.google_tag_manager[gtmKey]?.dataLayer && window?.google_tag_manager[gtmKey]?.dataLayer.reset) {
  //       window?.google_tag_manager[gtmKey]?.dataLayer?.reset()
  //     }
  //   }
  // }
}
export const pageview = (url, code) => {
  if (process.env.NEXT_PUBLIC_GOOGLE === `true`) {
    sendGAEvent(`config`, code, {
      page_path: url,
    })
  }
}
export const event = ({ action, params }) => {
  if (process.env.NEXT_PUBLIC_GOOGLE === `true`) {
    sendGAEvent(`event`, action, params)

    // sendGTMEvent({ event: action, value: params })
  }
}
export const consent = ({ action, params }) => {
  if (process.env.NEXT_PUBLIC_GOOGLE === `true`) {
    // sendGTMEvent({ event: `consent`, value: params, action })

    sendGAEvent(`consent`, action, params)
  }
}
