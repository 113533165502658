'use client'
/* eslint-disable no-extra-parens */
import TWLabel from "@/components/tailwind/Label"
import { LabelsContext } from "@/context/tools/labels"
import { ToolsContext } from "@/context/tools/tools"
import { types } from "@/types"
import parse from "html-react-parser"
import { useContext, useMemo } from "react"

export const Labels = ({ ...props }) => {
  const {
    events,
    element,
    seeker = false,
    bike = false,
    colors,
    small,
  } = props
  const { labels } = useContext(LabelsContext)
  const { categories, discount } = element
  const { tools: { texts }} = useContext(ToolsContext)
  const blackFriday = labels?.find(x => x.blackfriday)
  const special = labels?.find(x => x.special)
  const news = labels?.find(x => x.news)
  const outlet = labels?.find(x => x.outlet)

  const params = useMemo(() => {
    const { generic, bike_page } = texts
    const p = {
      seeker,
      backgroundcolor: discount > 0 ? colors.secondary : `transparent`,
      weight: 600,
      text: !bike ? (discount > 0 ? `${generic?.discount ?? ``} ${discount}${`%`}` : ``) : ``,
      show: true
    }

    switch (true) {
    case bike:
      p.backgroundcolor = colors.main
      p.text = bike_page?.newBike
      p.font = 16
      break
    case events[types.events.blackFriday] && !!blackFriday && categories?.includes(blackFriday?.category_id):
      p.backgroundcolor = blackFriday.color
      p.text = blackFriday.info.h1
      break
    case events[types.events.special] && !!special && categories?.includes(special?.category_id):
      p.backgroundcolor = special.color
      p.text = special.info.h1
      break
    case !!news && categories?.includes(news?.category_id):
      p.backgroundcolor = news.color
      p.text = news.info.h1
      break
    case !!outlet && categories?.includes(outlet?.category_id):
      p.backgroundcolor = outlet.color
      p.text = outlet.info.h1
      break

    default:
      p.show = discount > 0
      break
    }
    return p
  }, [ texts, labels ])

  return !!texts.generic && (params.show &&
        <TWLabel
          {...{ colors, small }}
          rounded
          bgColor={params.backgroundcolor}
          textColor={params.color}
          posTop={params.seeker ? `-22px` : `5px`}
          fontSize={params.font ? `16px` : `12px`}
          text
        >
          {parse(params.text || ``)}
        </TWLabel>
  )
}
