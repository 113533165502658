import { TWButton } from "@/components/tailwind/Button"
import { CatalogueContext } from "@/context"
import { useMediaQuerys } from "@/hooks/utils/useMediaQuerys"
import { types } from "@/types"
import { handleKeyRandom } from "@/utils/functions"
import { constructPath } from "@/utils/functions/catalogue"
import { constants } from "@/utils/global"
import { useRouter } from "next/navigation"
import queryString from "query-string"
import {
  useContext,
  useId, useMemo, useRef, useState
} from "react"
import { FaArrowDownShortWide, FaArrowDownWideShort } from "react-icons/fa6"
import { MdImportExport, MdNewReleases } from "react-icons/md"
import { DropDownArrows } from "../../basket/subcomponent/components/subcomponents/DropDownArrows"
import { PopperFilter } from "../desktop"
import { DrawerEdge } from "../drawer"

export const OrderFilter = ({ ...props }) => {
  const router = useRouter()
  const { info, handlePathFilter, handleLoading } = useContext(CatalogueContext)
  const { pathFilter, cleanPath } = info
  const { filters, colors, prodList: { result }, pageTexts } = props
  const anchorRef = useRef()
  const idRef = useId()
  const [ open, setOpen ] = useState(false)
  const { tablet } = useMediaQuerys()

  const options = useMemo(() => {
    const { order, ...opt } = types.catalog.order
    const tmpOpt = Object.entries(opt).map(x => {
      const tmpElem = {
        [types.catalog.order.desc]: <FaArrowDownWideShort style={{ color: colors.secondary }}/>,
        [types.catalog.order.asc]: <FaArrowDownShortWide style={{ color: colors.secondary }}/>,
        [types.catalog.order.new]: <MdNewReleases style={{ color: colors.secondary }}/>,
      }
      const element = tmpElem[x[1]]
      return {
        ...constants.OBJ_FILTER,
        type: order,
        parent: types.catalog.get,
        childId: x[1],
        pathParams: [ `GET`, `o`, x[1] ],
        element,
      }
    })
    return tmpOpt
  })
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }
  const handleChangePath = async item => {
    const flgValue = pathFilter.find(x => x.type === types.catalog.order.order)?.childId === item.childId
    const params = await queryString.parse(globalThis.window.location.search, {
      arrayFormat: `bracket-separator`,
      arrayFormatSeparator: `|`
    })
    const { o, ...tempParams } = params
    const finalParams = {
      ...params,
      o: item.childId,
    }
    const newFilter = flgValue ? await pathFilter.filter(x => x.type !== types.catalog.order.order) : [ pathFilter.filter(x => x.type !== types.catalog.order.order), item ].flat()

    await handlePathFilter(newFilter)
    await handleLoading(true)
    await constructPath(
      flgValue ? tempParams : finalParams, newFilter, cleanPath, router
    )
  }
  const MenuItems = () => (
    <>
      {options.map((item, i) => (
        <div key={handleKeyRandom(idRef, i)} onClick={() => handleChangePath(item)} className="flex cursor-pointer text-sm line-clamp-1 hover:bg-neutral-100 m-0 py-1 px-2 text-wrap border-b-2 border-neutral-50">
          <input
            className="bg-white mr-1"
            type="checkbox"
            aria-label="static-item"
            defaultChecked={!!filters.find(x => x.childId === item.childId)}
          />
          <label className="line-clamp-1">
            {pageTexts?.order_list[i] ?? ``}
          </label>
        </div>
      ))}
    </>
  )

  return (
    <div>
      <TWButton
        {...{ colors }}
        id="order-filter"
        ref={anchorRef}
        type="dropdown"
        bg={colors.lightGrey}
        border={colors.lightGrey3}
        aria-controls={open ? `split-filter-order` : undefined}
        aria-expanded={open ? `true` : undefined}
        aria-label="order-filters"
        aria-haspopup=""
        onClick={() => setOpen(!open)}
        className="w-full rounded-l-none tablet:rounded-l-full py-[2px] px-[6px] min-w-40"
      >
        {filters[0] ? filters[0].element : <MdImportExport className={`text-neutral-600 w-4 h-4`} />}
        <p>
          {pageTexts?.order_by ?? ``}
        </p>
        <DropDownArrows {...{ open }} className="text-neutral-600"/>
      </TWButton>
      {tablet && <PopperFilter {...{ open, anchorRef, handleClose }}>
        <MenuItems />
      </PopperFilter>}
      {!tablet && <div className="col-span-2">
        <DrawerEdge {...props} {...{ pageTexts, result, colors, open, setOpen, type: types.catalog.order.order, text: pageTexts?.order_by ?? `` }}>
          <MenuItems/>
        </DrawerEdge>
      </div>
      }
    </div>
  )
}
