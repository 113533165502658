'use client'
import { CartContext } from '@/context'
import { fixTwoDecimal } from '@/utils/functions'
import { useContext } from 'react'

const CardPrice = ({
  cost, pvp, discount, colors, small, mobile
}) => {
  const { cart: { currency }} = useContext(CartContext)
  const classPrice = `${small || mobile ? `text-sm` : `text-base`}`
  return discount <= 0 || parseFloat(cost) >= parseFloat(pvp) ? (
    <p style={{ color: colors.secondary }} className={`${classPrice} font-bold `}>
      {fixTwoDecimal(cost * (currency?.exchange ?? 1))}
      {currency?.symbol}
    </p>
  ) : (
    <div className={`flex justify-between items-end`} >
      <p style={{ color: colors.secondary }} className={`${classPrice} font-bold `}>
        {fixTwoDecimal(cost * (currency?.exchange ?? 1))}
        {currency?.symbol}
      </p>
      <p style={{ color: colors.darkGrey }} className={`${classPrice} line-through`}>
        {fixTwoDecimal(pvp * (currency?.exchange ?? 1))}
        {currency?.symbol}
      </p>
    </div>
  )
}

export default CardPrice
