const STORAGE_PREFIX = `svg-cache-`

const getCacheKey = (
  path, size, fillcolor, wdth, hght, name
) => `${STORAGE_PREFIX}${path}-${size}-${fillcolor}-${wdth}-${hght}-${name}`

const getFromStorage = cacheKey => {
  try {
    return localStorage.getItem(cacheKey)
  } catch (error) {
    console.warn(`LocalStorage access failed:`, error)
    return null
  }
}

const saveToStorage = (cacheKey, svgString) => {
  try {
    localStorage.setItem(cacheKey, svgString)
  } catch (error) {
    console.warn(`LocalStorage save failed:`, error)
  }
}

export const processSvg = async(
  path,
  size = 25,
  fillcolor = `black`,
  wdth = null,
  hght = null,
  name = `svgIcon`
) => {
  const cacheKey = getCacheKey(
    path, size, fillcolor, wdth, hght, name
  )

  // Check localStorage
  const cachedSvg = getFromStorage(cacheKey)
  if (cachedSvg) {
    return cachedSvg
  }

  try {
    const data = await (await fetch(path)).text()
    const svgDoc = new DOMParser().parseFromString(data, `image/svg+xml`)
    const svgElement = svgDoc.querySelector(`svg`)

    const attributes = {
      id: name,
      width: `${wdth ?? size}px`,
      height: `${hght ?? size}px`,
      fill: fillcolor
    }
    Object.entries(attributes).forEach(([ key, value ]) => svgElement.setAttribute(key, value))
    svgElement.removeAttribute(`class`)

    svgElement.querySelectorAll(`path`).forEach(pathElement => {
      pathElement.removeAttribute(`class`)
      pathElement.setAttribute(`fill`, fillcolor)
      pathElement.setAttribute(`stroke`, fillcolor)
    })

    const result = new XMLSerializer().serializeToString(svgElement)
    saveToStorage(cacheKey, result)
    return result
  } catch (error) {
    console.error(`SVG processing failed:`, error)
    return null
  }
}
