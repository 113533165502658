/* eslint-disable default-param-last */
/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-depth */
'use client'
import { apiCallElk } from "@/middleware/api"
import { types } from "@/types"
import { constants } from "@/utils/global"
import { ArrowDownward, ArrowUpward, NewReleases } from "@mui/icons-material"
import queryString from "query-string"
import { useEffect, useState } from "react"

export const useFilter = props => {
  const [ filters, setFilters ] = useState(props.catalogue.filters)
  const [ filPath, setFilPath ] = useState([])

  const setParamsFilter = async(
    filterParams, brand, fill = [], lang, list = []
  ) => {
    const iconStyle = { fontSize: 18 }
    const tmpElem = {
      [types.catalog.order.desc]: <ArrowDownward sx={iconStyle} />,
      [types.catalog.order.asc]: <ArrowUpward sx={iconStyle} />,
      [types.catalog.order.new]: <NewReleases sx={iconStyle} />,
    }
    const params = await queryString.parse(globalThis.window.location.search, {
      arrayFormat: `bracket-separator`,
      arrayFormatSeparator: `|`
    })
    const tmpParams = Object.entries(params).map(x => {
      switch (x[0]) {
      case `r`:
        return {
          ...constants.OBJ_FILTER,
          type: types.catalog.priceRange,
          parent: types.catalog.get,
          pathParams: [ `GET`, `r`, x[1] ],
          price: {
            min: x[1][0],
            max: x[1][1],
          },
        }
      case `o`:
        const element = tmpElem[x[1]]
        return {
          ...constants.OBJ_FILTER,
          type: types.catalog.order.order,
          childId: x[1],
          parent: types.catalog.get,
          pathParams: [ `GET`, `o`, x[1] ],
          element,
        }
      case `p`:
        return {
          ...constants.OBJ_FILTER,
          type: types.catalog.page,
          parent: types.catalog.get,
          pathParams: [ `GET`, `p`, parseInt(x[1], 10) ],
          page: parseInt(x[1], 10),
        }
      case `mbn`:
      case `mb`:
      case `my`:
      case `mm`:
        return {
          ...constants.OBJ_FILTER,
          type: types.catalog.meta,
          parent: types.catalog.get,
          childId: x,
          pathParams: [ `GET`, x[0], x[1] ],
        }

      default:
        return false
      }
    })
    const tmpArr = await filterParams?.map((x, i) => {
      let tmp
      let tmpSearch
      let child
      let splitter
      if (x?.length > 0) {
        switch (true) {
        case x[0].includes(`-`):
          splitter = x[0]?.split(`-`)
          tmp = fill.find(y => y.translations[lang].toLowerCase() === decodeURI(splitter[0]))
          if (tmp) {
            let tmpSpliter = ``
            if (splitter[0] !== `search` && splitter?.length > 2) {
              splitter.forEach((x, i) => {
                if (i === 1) {
                  tmpSpliter = splitter[i]
                } else if (i > 1) {
                  tmpSpliter = `${tmpSpliter}-${splitter[i]}`
                }
              })
            } else {
              tmpSpliter = splitter[1]
            }
            child = tmp.attributes.find(z => JSON.parse(z.translations)[lang].toLowerCase() === decodeURI(tmpSpliter))
            if (child) {
              return {
                ...constants.OBJ_FILTER,
                type: types.catalog.product,
                parent: tmp.parent,
                parentId: tmp.attribute_id,
                childId: child?.id || null,
                pathParams: [`${tmp.translations[lang]}-${JSON.parse(child.translations)[lang]}`.toLowerCase()],
              }
            }
          } else if (splitter[0] === `search`) {
            tmpSearch = fill.find(y => y.attribute_id === 30)
            if (tmpSearch) {
              child = tmpSearch.attributes.find(z => z.model.toLowerCase() === decodeURI(splitter[1]) && z.cc.toString() === decodeURI(splitter[2]) && z.year.toString() === decodeURI(splitter[3]))
              if (child) {
                return {
                  ...constants.OBJ_FILTER,
                  type: types.catalog.product,
                  parent: tmpSearch.translations[lang?.toLowerCase()],
                  parentId: tmpSearch.attribute_id,
                  childId: child?.id || null,
                  model_id: child?.model_id,
                  model: {
                    model: child.model,
                    cc: child.cc,
                    year: child.year
                  },
                  pathParams: [`search-${child.model}-${child.cc}-${child?.year}`.toLowerCase()],
                }
              }
            }
          } else if (splitter[0] === types.catalog.category) {
            let tmpSpliter = ``
            if (splitter?.length > 2) {
              splitter.forEach((x, i) => {
                if (i === 1) {
                  tmpSpliter = splitter[i]
                } else if (i > 1) {
                  tmpSpliter = `${tmpSpliter}-${splitter[i]}`
                }
              })
            } else {
              tmpSpliter = splitter[1]
            }

            child = list.find(z => z.info.url.toLowerCase() === tmpSpliter)
            if (child) {
              return {
                ...constants.OBJ_FILTER,
                type: types.catalog.category,
                parentId: child.info.id,
                pathParams: [`${types.catalog.category}-${child.info?.url}`],
              }
            }
          }
          break

        default:
          tmp = brand?.find(y => y.webdata.url === x[0])
          if (tmp) {
            return {
              ...constants.OBJ_FILTER,
              type: types.catalog.brand,
              parentId: tmp.brand_id,
              brandName: tmp.name,
              pathParams: [tmp.webdata.url.toLowerCase()],
            }
          }
          break
        }
      }
      return false
    })
    await setFilPath([ tmpArr, tmpParams ].flat())
    return [ tmpArr, tmpParams ].flat()
  }

  const getFilter = async prop => {
    const { brands, catalogue, lang } = prop
    await setParamsFilter(
      catalogue.filterParams, brands, [], lang
    )

    // return filters
  }
  useEffect(() => {
    getFilter(props)
  }, [])
  return {
    filters,
    filPath,
    getFilter,
    setParamsFilter
  }
}

export const useBikeFilters = props => {
  const { hostname } = props
  const { business, platformId, langId } = hostname
  const [ filters, setFilters ] = useState([])

  const hndlBikeFilters = async prop => {
    const obj = {
      ...prop,
      abbrElastic: business.abbrElastic
    }
    const { code, result } = await apiCallElk({
      url: `/filter/bike`,
      type: `POST`,
      encrypt: obj
    })
    await setFilters(result)
  }

  useEffect(() => {
    filters?.length === 0 && hndlBikeFilters({
      platformId,
      languageId: langId
    })
  }, [hostname])

  return {
    filters
  }
}
