import { CatalogueContext } from "@/context"
import { useContext, useEffect } from "react"
export const useFilterPops = (
  setParamsFilter, handlePathFilter, brands, codeLang, specialList
) => {
  const {
    info: { filters },
  } = useContext(CatalogueContext)
  const execute = async(
    set, handle, brand, lang, filter, list
  ) => {
    const path = await globalThis.window.location.pathname?.split(`/`).slice(2)
    const tmpPath = path.map(x => [decodeURI(x)])
    const tmpArr = await set(
      tmpPath, brand, filter, lang, list
    )
    await handle(tmpArr.filter(x => x !== false))
  }
  useEffect(() => {
    globalThis.window.onpopstate = ({ state, ...e }) => {
      execute(
        setParamsFilter, handlePathFilter, brands, codeLang, filters, specialList
      )
    }
  })
  return { execFilterProps: execute }
}
