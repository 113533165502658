import { Status } from "@googlemaps/react-wrapper"

export const renderMap = status => {
  switch (status) {
  case Status.FAILURE:
    return false
  case Status.SUCCESS:
    return true
  default:
    return true
  }
}

export const constructMarkers = points => {
  const markers = []
  points.map((point, indx) => markers.push([
    {
      lat: parseFloat(point.latitude),
      lng: parseFloat(point.longitude)
    }, {
      name: point.name,
      street: point.address,
      number: point.streetNumber
    }, indx
  ]))
  return markers
}

// ? aqui se modifican los markers que aparecen en el mapa
export const addMarker = (
  markerData, map, infoWindow, setPickupSelected, colors
) => {
  const marker = new window.google.maps.Marker({
    position: markerData[0],
    title: `<h5 style="color: ${colors.main}">${markerData[1].name}</h5> <p style="font-weight:500;">${markerData[1].street} ${markerData[1].number || ``}</p>`,
    label: {
      text: `${markerData[2] + 1}`,
      color: colors.white
    },
    map,
  })
  marker.addListener(`click`, () => {
    setPickupSelected(markerData[2])
    infoWindow.close()
    infoWindow.setContent(marker.getTitle())
    infoWindow.open(marker.getMap(), marker)
  })
  return marker
}

export const clickMarker = (markers, index) => {
  window.google.maps.event.trigger(markers[index], `click`)
}
