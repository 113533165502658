/**
 * TWLabel component renders a styled label using Tailwind CSS classes.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.bgColor - The background color of the label.
 * @param {string} props.textColor - The text color of the label.
 * @param {string} [props.posTop='5px'] - The top position of the label.
 * @param {string} [props.posLeft='6px'] - The left position of the label.
 * @param {Object} props.colors - An object containing color values.
 * @param {boolean} [props.small=false] - If true, applies smaller padding to the label.
 * @param {string} [props.fontSize='13px'] - The font size of the label text.
 * @param {React.ReactNode} props.children - The content to be displayed inside the label.
 * @param {boolean} props.rounded - If exists, applies full rounding to the label, if it has a value, applies that.
 *
 * @returns {JSX.Element} The rendered label component.
 */
const TWLabel = ({
  children, colors, className = ``, rounded = null, bgColor, textColor = null, posTop = `5px`, posLeft = `6px`, small = null, fontSize = `13px`, minW = null, text = null
}) => {
  return (
    <div name="tailwind-label" style={{
      backgroundColor: bgColor ?? colors.main,
      top: posTop,
      left: posLeft,
      borderRadius: rounded,
      minWidth: minW ?? `50px`
    }}
    className={`${className} absolute z-50 ${rounded ? `rounded-full` : `rounded-md`} ${minW ? `p-0` : small ? `py-[1px] px-3` : `py-1 px-3`}  font-semibold text-center`} >
      {/* // TODO - ${business.rounded ? `rounded-full` : ``}  también el tipo de fuente// */}
      {text ?
        <p style={{ fontSize, color: textColor ?? colors.mainContrast }} className="font-semibold">{children}</p> :
        children
      }
    </div>
  )
}

export default TWLabel
