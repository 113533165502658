export const TWDivider = ({ className, margin = null, mx = 0, my = 16, thin = null, dotted = null, vertical = null, size = 95, color = `` }) => {
  const lineClass = `${thin ? `${vertical ? `border-r` : `border-b`}` : `${vertical ? `border-r-2` : `border-b-2`}`} ${dotted ? `border-dotted` : ``}`
  return <div
    name="divider"
    style={{ margin: margin ?? vertical ? `auto` : `${my}px ${mx}px`, width: !vertical && `${size}%`, height: vertical && `${size}%` }}
    className={`${className} ${!vertical ? `flex justify-center items-center` : lineClass}`}>
    <span name="divider-line"
      style={{ borderColor: color, width: !vertical && `${size}%`, height: vertical && `${size}%` }}
      className={!vertical ? lineClass : ``} />
  </div>
}
