/* eslint-disable max-lines */
import { config } from "@/middleware/config/config"
import { ga } from "@/utils/functions/google"
import { constants } from "@/utils/global"
import Cookies from "js-cookie"
import { fixTwoDecimal } from ".."
import { Domain } from "@mui/icons-material"

const {
  COOKIES: { COOKIES_POLICY, COOKIES_AD_USER_DATA, COOKIES_AD_PERSONALIZATION },
} = config

/* eslint-disable no-extra-parens */
const gtagProductList = (props, host) => {
  const { business, domain } = host
  const obj = {
    item_id: null,
    item_name: ``,
    affiliation: business.webUrl + domain,
    discount: 0,
    item_brand: ``,
    item_category: ``,
    item_list_id: `other_prods`,
    item_list_name: `other_prods`,
    location_id: constants.LOCATION,
    price: null,
    quantity: null,
  }
  const arrProdGtag = props.catalogue.map(x => ({
    ...obj,
    item_id: x.prod_id,
    item_name: x.title,
    item_brand: x.brand,
    item_category: x.product_categories_name,
    quantity: 1,
    price: parseFloat(x.cost),
    discount: x.discount,
  }))
  return arrProdGtag
}
const gtagProductIds = props => {
  const arrProdIdGtag = props.catalogue.map(x => x.prod_id)
  return arrProdIdGtag
}
export const createPageView = props => {
  const { products, hostname: { business, domain }} = props
  const arrProd = gtagProductList(products, { business, domain })
  const arrProdID = gtagProductIds(products)
  return {
    arrProd,
    arrProdID
  }
}
const gtagLines = (prop, obj, cat) => {
  const tmp = prop.variant?.map(x => ({
    ...obj,
    item_id: x.product_id,
    item_name: prop.webdata.title,
    item_brand: x.brand_name,
    item_category: cat,
    quantity: 1,
    price_go: parseFloat(fixTwoDecimal(x.pack ? x.pack.price : x.price.cost)),
    price_fc: `${parseFloat(fixTwoDecimal(x.pack ? x.pack.price : x.price.cost))} EUR`,
    discount: prop.pack ? 0 : x.price.discount > 0 ? x.price.discountIVA : 0,
    item_variant: prop.pack ? prop.webdata.title : x.variant_name,
    availability: x.web_availability === `0` ? `in stock` : `out of stock`,
  }))
  return tmp
}
export const createPageItem = (props, cat, host) => {
  const { business, domain } = host
  const obj = {
    item_id: null,
    item_name: ``,
    affiliation: business.webUrl + domain,
    discount: 0,
    item_brand: ``,
    item_category: ``,
    item_list_id: `other_prods`,
    item_list_name: `other_prods`,
    item_variant: ``,
    location_id: constants.LOCATION,
    price_go: null,
    price_fc: null,
    quantity: null,
    availability: ``,
  }
  const items = gtagLines(props, obj, cat)
  return {
    currency: `EUR`,
    value: parseFloat(fixTwoDecimal(props.pack ? props.pack.cost : props.variant[0].price.cost)),
    content_ids: [props?.id.toString()],
    price: `${fixTwoDecimal(props.pack ? props.pack.cost : props.variant[0].price.cost)} EUR`,
    availability: props.variant.find(x => x.web_availability === `0`) ? `in stock` : `out of stock`,
    items,
  }
}

export const gtagLineList = (
  props, total, hostname, flag = false
) => {
  const { business, domain } = hostname
  const obj = {
    item_id: null,
    item_name: ``,
    affiliation: business.webUrl + domain,
    discount: 0,
    item_brand: ``,
    item_category: ``,
    item_list_id: `other_prods`,
    item_list_name: `other_prods`,
    item_variant: ``,
    location_id: constants.LOCATION,
    price: null,
    quantity: null,
  }
  const arrLines = props?.lines?.map(x => ({
    ...obj,
    item_id: x.product_id,
    item_name: x.name,
    item_brand: x.brand_name,
    item_category: x.category,
    item_variant: x.attribute_name || x.variant_name,
    quantity: x.amount,
    price: parseFloat(x.total),
  }))
  const arrPacks = props?.pack_lines?.map(x => ({
    ...obj,
    item_id: x.product_id,
    item_name: x.name,
    item_brand: `EuroBikes`,
    item_category: x.category,
    item_variant: x.attribute_name || x.variant_name,
    quantity: x.amount,
    price: parseFloat(x.total_price),
  }))
  const result = {
    currency: `EUR`,
    value: parseFloat(total),
    items: [ ...arrLines, ...arrPacks ]
  }
  if (flag) {
    result.shipping_tier = `Ground`
  }
  return result
}
export const gtagPurchaseItems = (confirm, hostname) => {
  const { business, domain } = hostname
  const obj = {
    item_id: null,
    item_name: ``,
    affiliation: business.webUrl + domain,
    discount: 0,
    item_brand: ``,
    item_category: ``,
    item_list_id: `other_prods`,
    item_list_name: `other_prods`,
    item_variant: ``,
    location_id: constants.LOCATION,
    price: null,
    quantity: null,
  }
  const arrLines = confirm.lines.lines.map(x => ({
    ...obj,
    item_id: x.product_id,
    item_name: x.name,
    item_brand: x.brand_name,
    item_category: x.category,
    item_variant: x.attribute_name || x.variant_name,
    quantity: x.amount,
    price: parseFloat(x.total),
  }))
  const arrPacks = confirm.lines.pack_lines.map(x => x.pack.map(b => ({
    ...obj,
    name: b.variant_name,
    brand: `EuroBikes`,
    category: x.category,
    quantity: b.pack_amount,
    item_variant: b.attribute_name || b.variant_name,
    price: parseFloat(b.total),
  })))

  const result = {
    transaction_id: confirm.pay_method_id !== 9 ? confirm.order_reference : confirm.draft_reference,
    content_ids: arrLines.map(x => x?.item_id?.toString()),
    currency: `EUR`,
    value: parseFloat(confirm.total_price),
    tax: parseFloat(fixTwoDecimal(confirm.taxes_qty)),
    shipping: parseFloat(fixTwoDecimal(confirm.shipping_cost)),
    items: [ ...arrLines, ...arrPacks.flat() ],
  }
  return result
}
export const gtagGenerateLead = (confirm, data, hostname) => {
  const { business, domain } = hostname
  const obj = {
    item_id: null,
    item_name: ``,
    affiliation: business.webUrl + domain,
    discount: 0,
    item_brand: ``,
    item_category: ``,
    item_list_id: `other_prods`,
    item_list_name: `other_prods`,
    item_variant: ``,
    location_id: constants.LOCATION,
    price: null,
    quantity: null,
  }
  const arrLines = confirm.variants.map(x => ({
    ...obj,
    item_id: x.product_id,
    item_name: x.product_name,
    item_brand: x.brand_name,
    item_category: x.category,
    item_variant: x.attribute_name ?? x.variant_name,
    quantity: x.amount ?? 1,
    price: fixTwoDecimal(parseFloat(x.price.cost)),
  }))

  const result = {
    transaction_id: confirm.id,
    content_ids: arrLines.map(x => x?.item_id?.toString()),
    currency: `EUR`,
    value: fixTwoDecimal(parseFloat(confirm.variants[0].price.cost)),
    items: [...arrLines],
    formData: data,
  }
  return result
}
export const gtagPurchaseOrder = (cart, orderItems) => ({
  currency: `EUR`,
  tax: cart.taxes_qty,
  checkout_option: cart.pay_method.name,
  transaction_id: cart.id,
  affiliation: globalThis.window.location.host,
  value: `${cart.total_price}`,
  shipping: cart.shipping_cost,
  items: orderItems,
})
const calculateDiscount = prop => {
  const data = prop
  if (prop.salesPrice < prop.price) {
    data.discount = 100 - Math.round((prop.salesPrice * 100) / prop.price)
  }
  return data
}

export const datalayerPurchaseOrder = (cart, orderItems, hostname) => {
  const summaryLines = {
    salesPrice: 0,
    price: 0,
    discount: 0,
  }
  cart.lines.lines.forEach(x => {
    summaryLines.salesPrice += x.price.cost_iva * x.amount
    summaryLines.price += x.price.pvp_iva * x.amount
  })
  cart.lines.pack_lines.forEach(x => {
    summaryLines.salesPrice += parseFloat(x.total_price)
    summaryLines.price += parseFloat(x.total_price)
  })
  const country = hostname?.domain === `com` ? `ES` : hostname?.domain === `net` ? `DE` : hostname?.domain
  return {
    event: `purchase`,
    transaction_id: cart.id,
    value: cart.total_price,
    currency: `EUR`,
    tax: cart.taxes_qty,
    shipping: cart.shipping_cost,
    checkout_option: cart.pay_method.name,
    discount: calculateDiscount(summaryLines).discount,
    aw_merchant_id: hostname?.google?.merchant,
    aw_feed_country: country.toUpperCase(),
    aw_feed_language: hostname?.lang,
    items: orderItems,
  }
}

export const pageViewPurchase = cart => {
  const arrVariantId = cart.lines.lines.map(x => x.variant_id)
  const arrVariantIdPack = cart.lines.pack_lines.map(x => x.pack.map(b => b.variant_id))
  return {
    ecomm_pagetype: `purchase`,
    ecomm_prodid: [ ...arrVariantId, ...arrVariantIdPack.flat() ],
    ecomm_totalvalue: cart.total_price,
  }
}

export const pageViewAdwords = (item, prod) => {
  let items = []
  let total = 0
  if (!prod) {
    total = item?.pack !== undefined ? item?.pack.cost : item.cost.min
    items.push({
      id: item.id,
      google_business_vertical: `retail`,
    })
  } else {
    total = item.total_price
    const arrLines = item.lines.lines.map(x => ({
      id: x.product_id,
      google_business_vertical: `retail`,
    }))
    const arrPacks = item.lines.pack_lines.map(x => ({
      id: x.product_id,
      google_business_vertical: `retail`,
    }))

    items = [ ...arrLines, ...arrPacks ]
  }

  return {
    items,
    total
  }
}

export const gtagAddToCart = async(
  product, items, amount, lines, breadCrumbs, hostname
) => {
  const { business, domain } = hostname
  const addedItem = await items.map(item => {
    const { line, pack } = item
    let tmp = {
      item_id: product.id,
      item_name: product?.webdata?.title || product?.name,
      affiliation: business.webUrl + domain,
      discount: line?.discountIVA || 0,
      item_brand: item.brand_name,
      item_variant: item.attribute_name || item.variant_name,
      location_id: constants.LOCATION,
      item_list_id: `other_prods`,
      item_list_name: `other_prods`,
      price: line?.pvpIVA || pack?.price || lines.reduce((a, b) => a + b, 0),
      quantity: Math.abs(parseInt(amount, 10)),
    }
    if (breadCrumbs) {
      tmp = {
        ...tmp,
        item_category: breadCrumbs[0]?.h1,
        item_category2: breadCrumbs[1]?.h1 || null,
        item_category3: breadCrumbs[2]?.h1 || null,
      }
    }
    return tmp
  })
  const qtty = Math.abs(parseInt(amount, 10))
  const total = lines.reduce((a, b) => a + b, 0) * qtty
  const ret = await {
    currency: `EUR`,
    content_ids: addedItem.map(x => x?.item_id?.toString()),
    value: Math.abs(parseFloat(fixTwoDecimal(total))),
    items: addedItem,
  }
  return ret
}

export const googleCookies = () => {
  ga.clearDL()
  const adUser = Cookies.get(config.COOKIES.COOKIES_AD_USER_DATA)
  const adPrsnlztn = Cookies.get(config.COOKIES.COOKIES_AD_PERSONALIZATION)
  const granted = adUser === `1` ? `granted` : `denied`
  const params = {
    ad_user_data: granted,
    ad_storage: granted,
    analytics_storage: granted,
    functionality_storage: granted,
    personalization_storage: granted,
    security_storage: granted,
    ad_personalization: adPrsnlztn === `1` ? `granted` : `denied`,
  }
  ga.consent({
    action: `update`,
    params
  })
}

export const googleConsent = () => {
  ga.clearDL()
  const adUser = Cookies.get(config.COOKIES.COOKIES_AD_USER_DATA)
  const adPrsnlztn = Cookies.get(config.COOKIES.COOKIES_AD_PERSONALIZATION)
  return adUser === `1` && adPrsnlztn === `1`
}
