'use client'
import { useState } from "react"
import { useInView } from "react-intersection-observer"

export const useView = hostname => {
  const [ view, setView ] = useState(hostname?.isRobot ?? false)
  const { ref, inView } = useInView({
    threshold: 0,
    initialInView: hostname?.isRobot ?? false,
    onChange: v => {
      if (v && !view) {
        setView(x => v)
      }
    }
  })

  return { ref, view, inView }
}
