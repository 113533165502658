/* eslint-disable no-extra-parens */

/* eslint-disable space-before-function-paren */
import { TWButton } from "@/components/tailwind/Button"
import { CatalogueContext, FiltersContext } from "@/context"
import { useFilterSearch } from "@/hooks/filter"
import { types } from "@/types"
import { fixTwoDecimalNotFixed, handleKeyRandom, isDark } from "@/utils/functions"
import { constructPath } from "@/utils/functions/catalogue"
import { constants } from "@/utils/global"
import parse from "html-react-parser"
import { useRouter } from "next/navigation"
import queryString from "query-string"
import {
  useContext, useEffect, useId, useRef, useState
} from "react"
import { GrStatusGood } from "react-icons/gr"
import { DropDownArrows } from "../../basket/subcomponent/components/subcomponents/DropDownArrows"
import { PopperFilter } from "../desktop"
import { FilterDrawer } from "../drawer"
import { useMediaQuerys } from "@/hooks/utils/useMediaQuerys"

export const DynamicFilter = ({ ...props }) => {
  const router = useRouter()
  const {
    info: { pathFilter, cleanPath },
    handlePathFilter, handleLoading
  } = useContext(CatalogueContext)
  const {
    mobile, fields, attr, web, filters, filter, options, show, disabled, fill, divMobile, brands, motovo, hostname
  } = props
  const { colors } = hostname
  const anchorRef = useRef()
  const idRef = useId()
  const { fOpen, getFilterInit } = useContext(FiltersContext)
  const [ open, setOpen ] = useState(false)
  const [ textSearch, setTextSearch ] = useState(``)
  const { tablet } = useMediaQuerys()
  const option = useFilterSearch({
    items: options,
    search: textSearch,
    order: null
  })

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    mobile ? getFilterInit({
      name: filter.attribute_id,
      flag: false
    }) : setOpen(false)
  }

  const handleTextSearch = (e, value) => {
    e.preventDefault()
    setTextSearch(e.target.value)
  }

  useEffect(() => {
    !open && setTextSearch(``)
  }, [open])

  const handleChangePath = async (item, obj) => {
    const params = await queryString.parse(location.search, {
      arrayFormat: `bracket-separator`,
      arrayFormatSeparator: `|`
    })
    const newFilter = (await pathFilter.find(x => x.childId === item.id)) ?
      await pathFilter.filter(x => (x.type === types.catalog.product && x.childId !== item.id) || x.type !== types.catalog.product) :
      [ ...pathFilter, obj ]
    await handlePathFilter(newFilter)
    await handleLoading(true)
    await constructPath(
      params, newFilter, cleanPath || ``, router
    )
  }

  const Items = (
    id, item, obj, isColor, mobItem = false
  ) => {
    const isMetaMoto0 = filter.meta_moto === 0
    const isMetaMoto1 = filter.meta_moto === 1
    const isChildId = filters.find(x => x.childId === item.id)
    const isModelId = filters.find(x => x.model_id === item.model_id && x.childId === item.id)
    const isChecked = (isMetaMoto0 && !!isChildId) || (isMetaMoto1 && !!isModelId)
    const label = isMetaMoto0 ? JSON.parse(item.translations)[hostname?.lang?.toLowerCase()] : `${item.model} ${fixTwoDecimalNotFixed(item.cc)} - ${item?.year}`

    return (
      <>
        {!isColor ? (
          <>
            {!mobItem ? (
              <div onClick={() => handleChangePath(item, obj)} className="flex cursor-pointer text-sm line-clamp-1 hover:bg-neutral-100 m-0 py-1 px-2 text-wrap border-b-2 border-neutral-50">
                <input
                  className="bg-white mr-1 cursor-pointer"
                  type="checkbox"
                  aria-label="dynamic-item"
                  defaultChecked={isChecked}
                />
                <label className="line-clamp-1 cursor-pointer">{parse(label)}</label>
              </div>
            ) : (
              <button
                {...{ colors }}
                className={`px-[8px] rounded-full w-full py-[1px] whitespace-nowrap line-clamp-1 font-semibold text-[14px] text-white hover:text-neutral-500 bg-neutral-400 hover:bg-neutral-300 my-[1px]`}
                style={{ backgroundColor: isChildId && colors.secondary, color: isChildId && colors.white }}
                onClick={() => handleChangePath(item, obj)}
              >
                {label}
              </button>
            )}
          </>
        ) : <>
          <div>
            <button onClick={() => handleChangePath(item, obj)}
              style={{ backgroundColor: item.code }}
              className="w-7 h-7 rounded-full border-[1px] border-neutral-600 opacity-80 hover:opacity-100">
              {isChecked && <GrStatusGood style={{ color: isDark(item.code) ? colors.white : colors.dark }}/>}
            </button>
          </div>
        </>
        }
      </>
    )
  }

  const MenuItems = () => {
    return (
      <>
        {option.map((item, i) => {
          const isColor = (filter.type === `2`)
          const isMetaMoto0 = filter.meta_moto === 0
          const isMetaMoto1 = filter.meta_moto === 1
          const isAttrIncluded = attr.includes(item.id)
          const pathParams = isMetaMoto1 ? [`search-${item.model}-${item.cc}-${item?.year}`.toLowerCase()] : [`${filter.translations[hostname?.lang?.toLowerCase()]}-${JSON.parse(item.translations)[hostname?.lang.toLowerCase()]}`.toLowerCase()]
          const model = isMetaMoto1 ? {
            model: item.model,
            cc: item.cc,
            year: item.year
          } : null
          const obj = {
            ...constants.OBJ_FILTER,
            type: types.catalog.product,
            parent: isMetaMoto0 ? filter.parent : filter.translations[hostname?.lang?.toLowerCase()].toLowerCase(),
            parentId: filter.attribute_id,
            childId: item.id,
            meta_moto: filter.meta_moto,
            model_id: isMetaMoto1 ? item?.model_id : null,
            model,
            pathParams,
          }

          if (isMetaMoto0 && !isAttrIncluded) {
            return <div key={handleKeyRandom(idRef, i)}></div>
          }
          return <div className={`${isColor ? `p-1` : `p-0`}`} key={handleKeyRandom(idRef, i)}>
            {Items(
              filter.attribute_id, item, obj, isColor, isMetaMoto1
            )}
          </div>
        })}
      </>
    )
  }
  const ListMenu = prop => (
    <div className="max-w-[300px]">
      <input
        type="text"
        className="w-full p-2 flex outline-none border-b-2 border-neutral-200"
        id={`input-filter`}
        autoFocus={open}
        onChange={handleTextSearch}
        value={textSearch}
      />
      {(filter.type !== `2`) ? (
        <div id={idRef} className="max-h-52 overflow-y-auto pb-4">
          <MenuItems />
        </div>
      ) : (
        <div className="flex flex-wrap max-h-52 overflow-y-auto pb-4">
          <MenuItems />
        </div>
      )}
    </div>
  )

  const ListDrawer = () => (
    <>
      {option.map((item, i) => {
        const isColor = (filter.type === `2`)
        let pathParams = []
        let model = null
        if (filter.meta_moto === 1) {
          pathParams = [`search-${item.model}-${item.cc}-${item?.year}`.toLowerCase()]
          model = {
            model: item.model,
            cc: item.cc,
            year: item.year
          }
        } else {
          pathParams = [`${filter.translations[hostname?.lang?.toLowerCase()]}-${JSON.parse(item.translations)[hostname?.lang?.toLowerCase()]}`.toLowerCase()]
        }
        const obj = {
          ...constants.OBJ_FILTER,
          type: types.catalog.product,
          parent: filter.parent,
          parentId: filter.attribute_id,
          childId: item.id,
          meta_moto: filter.meta_moto,
          model_id: filter.meta_moto === 1 ? item?.model_id : null,
          model,
          pathParams,
        }
        return (
          <div className="mr-1" key={handleKeyRandom(idRef, i)}>
            {Items(
              filter.attribute_id, item, obj, isColor, true
            )}
          </div>
        )
      })}
    </>
  )
  return (
    <>
      {show && (
        <div>
          <TWButton
            {...{ colors }}
            id={idRef}
            ref={anchorRef}
            type="dropdown"
            bg={colors.lightGrey}
            className="w-[100%] border-0 tablet:border-[1px] tablet:pl-[12px] tablet:pr-[6px] tablet:py-[2px] tablet:min-w-28"
            border={colors.lightGrey3}
            aria-controls={fOpen[filter.attribute_id] ? `split-filter-dynamic` : undefined}
            aria-expanded={fOpen[filter.attribute_id] ? `true` : undefined}
            aria-label="dynamic-filters"
            aria-haspopup=""
            onClick={() => (mobile ? getFilterInit({
              name: filter.attribute_id,
              flag: !fOpen[filter.attribute_id]
            }) : setOpen(!open))}
            disabled={disabled}
          >
            <div className="flex">
              {filters?.length > 0 && (
                <div style={{ backgroundColor: colors.secondary }} className={`rounded-full h-4 w-4 text-white text-xs -ml-[6px] mr-1 mt-[2px]`}>
                  {filters.length}
                </div>
              )}
              <p className={`line-clamp-1 m-0`}>
                {parse(filter.translations[hostname.lang?.toLowerCase()])}
              </p>
            </div>
            <DropDownArrows {...{ open }} className="text-neutral-600"/>
          </TWButton>
          {tablet && <PopperFilter {...{ open, anchorRef, handleClose }}>
            <ListMenu />
          </PopperFilter>
          }
          {!tablet && <FilterDrawer {...{ open: fOpen[filter?.attribute_id], desk: false, mobile }}>
            <ListDrawer/>
          </FilterDrawer>
          }
        </div>
      )}
    </>
  )
}
