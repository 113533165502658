import { TWButton } from "@/components/tailwind/Button"
import { cmp, types } from "@/types"
import parse from "html-react-parser"
import { IoClose } from "react-icons/io5"
import { BottomDrawer } from "."
import SwipeableDrawer from "@mui/material/SwipeableDrawer"

export const DrawerEdge = props => {
  const { open, setOpen, type, text, colors } = props
  const handleClose = () => setOpen(!open)
  return (
    <SwipeableDrawer
      cmptype={cmp.drawerSwipeable}
      anchor="bottom"
      open={open}
      onClose={handleClose}
      onOpen={handleClose}
      ModalProps={{ keepMounted: true }}
    >
      <div
        className={`${type === types.catalog.order.order ? `h-auto` : `h-[80vh]`} px-4`}>
        <div className="flex justify-between p-4 pt-6">
          <p className="text-[20px] font-semibold leading-[0.8]">
            {parse(text)}
          </p>
          <TWButton
            {...{ colors }}
            className="text-[15px] rounded-full p-1"
            style={{ color: colors.mainContrast }}
            onClick={handleClose}>
            <IoClose/>
          </TWButton>
        </div>
        {props.children}
        <BottomDrawer {...props} />
      </div>
    </SwipeableDrawer>
  )
}
