export const getImageUrl = (imageUrl, thumb, domain) => {
  let url = ``
  const finalImage = imageUrl
  if (finalImage !== null) {
    if (finalImage?.includes(process.env.NEXT_PUBLIC_IMG_CHECK)) {
      // if (finalImage.startsWith(`http:`)) {
      //   finalImage = finalImage.replace(`http:`, `https:`)
      // }
      url = finalImage
    } else if (thumb.includes(domain) || thumb.includes(`io`)) {
      url = `${thumb}${finalImage}`
    } else {
      url = `${thumb}${domain === `us` ? `com` : domain}${finalImage}`
    }
  }
  return url
}
