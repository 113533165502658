import { useMemo } from "react"
import validator from "validator"
export const useFilterSearch = ({
  items, search, order, ...props
}) => {
  const filter = useMemo(() => {
    let temp = []
    temp = !validator.isEmpty(search) ?
      items.filter(x => x?.model ? `${x?.model} ${x.cc}`.toString().toUpperCase().
        includes(search.toUpperCase()) :
        x?.info ? x?.info.url.toString().toUpperCase().
          includes(search.toUpperCase()) :
          x?.webdata ? x?.webdata.h1.toString().toUpperCase().
            includes(search.toUpperCase()) :
            x?.translations.toString().toUpperCase().
              includes(search.toUpperCase())) :
      items
    return temp?.sort((a, b) => {
      if (a[order] > b[order]) {
        return 1
      }
      if (a[order] < b[order]) {
        return -1
      }
      return 0
    })
  }, [ items, search ])
  return filter
}
