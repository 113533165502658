/* eslint-disable no-extra-parens */
import { TWButton } from "@/components/tailwind/Button"
import { CatalogueContext } from "@/context"
import { useFilterSearch } from "@/hooks/filter"
import { types } from "@/types"
import { handleKeyRandom } from "@/utils/functions"
import { constructPath } from "@/utils/functions/catalogue"
import { constants } from "@/utils/global"
import parse from "html-react-parser"
import { useRouter } from "next/navigation"
import queryString from "query-string"
import {
  useContext, useEffect, useId, useRef, useState
} from "react"
import { DropDownArrows } from "../../basket/subcomponent/components/subcomponents/DropDownArrows"
import { PopperFilter } from "../desktop"
import { FilterDrawer } from "../drawer"
import { useMediaQuerys } from "@/hooks/utils/useMediaQuerys"

export const StaticFilter = props => {
  const router = useRouter()
  const {
    info: { pathFilter, cleanPath },
    handlePathFilter, handleLoading
  } = useContext(CatalogueContext)

  const { mobile, filters, options, pageTexts, colors } = props
  const anchorRef = useRef()
  const idRef = useId()
  const [ open, setOpen ] = useState(false)
  const [ textSearch, setTextSearch ] = useState(``)
  const { tablet } = useMediaQuerys()
  const option = useFilterSearch({
    items: options.filter(x => x !== false),
    search: textSearch,
    order: `h1`
  })

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleTextSearch = (e, value) => {
    e.preventDefault()
    setTextSearch(e.target.value)
  }
  useEffect(() => {
    !open && setTextSearch(``)
  }, [open])
  const handleChangePath = async(item, obj) => {
    const params = await queryString.parse(location.search, {
      arrayFormat: `bracket-separator`,
      arrayFormatSeparator: `|`
    })
    const newFilter = (await filters.find(x => x.parentId === item.brand_id)) ?
      pathFilter.filter(x => (x.type === types.catalog.brand && x.parentId !== item.brand_id) || x.type !== types.catalog.brand) :
      [ ...pathFilter, obj ]
    await handlePathFilter(newFilter)
    await handleLoading(true)
    await constructPath(
      params, newFilter, cleanPath, router
    )
  }
  const ListMenu = prop => (
    <div>
      <input
        type="text"
        className="w-full p-2 flex outline-none border-b-2 border-neutral-200"
        id={`input-filter`}
        autoFocus={open}
        onChange={handleTextSearch}
        value={textSearch}
      />
      <div id={idRef} className="max-h-52 overflow-x-hidden overflow-y-auto pb-4">
        {option.map((item, i) => {
          const obj = {
            ...constants.OBJ_FILTER,
            type: types.catalog.brand,
            parentId: item.brand_id,
            brandName: item.name,
            pathParams: [item.webdata?.url],
          }
          return (
            <div key={handleKeyRandom(idRef, i)} onClick={() => handleChangePath(item, obj)} className="flex cursor-pointer text-sm line-clamp-1 hover:bg-neutral-100 m-0 py-1 px-2 text-wrap border-b-2 border-neutral-50">
              <input
                className="bg-white mr-1 cursor-pointer"
                type="checkbox"
                aria-label="static-item"
                defaultChecked={!!filters.find(x => x.parentId === item.brand_id)}
              />
              <label className="line-clamp-1 cursor-pointer">{parse(item.webdata.h1)}</label>
            </div>)
        })}
      </div>
    </div>
  )

  const ListDrawer = () => (
    <div className="flex overflow-x-auto p-1">
      {option.map((item, i) => {
        const obj = {
          ...constants.OBJ_FILTER,
          type: types.catalog.brand,
          parentId: item.brand_id,
          brandName: item.name,
          pathParams: [item.webdata.url],
        }

        const selected = filters.find(x => x.parentId === item.brand_id)

        return (
          <div key={handleKeyRandom(idRef, i)} className="mr-1">
            <button
              className={`px-[8px] rounded-full w-full py-[1px] whitespace-nowrap line-clamp-1 font-semibold text-[14px] text-white hover:text-neutral-500 bg-neutral-400 hover:bg-neutral-300 my-[1px]`}
              style={{ backgroundColor: selected && colors.secondary, color: selected && colors.white }}
              onClick={() => handleChangePath(item, obj)}
            >
              {item.webdata.h1}
            </button>
          </div>
        )
      })}
    </div>
  )
  return (
    <>
      <TWButton
        {...{ colors }}
        id={idRef}
        ref={anchorRef}
        type="dropdown"
        bg={colors.lightGrey}
        className="w-[100%] border-0 tablet:border-[1px] tablet:pl-[12px] tablet:pr-[6px] tablet:py-[2px] tablet:min-w-28"
        border={colors.lightGrey3}
        aria-controls={open ? `split-filter-brand` : undefined}
        aria-expanded={open ? `true` : undefined}
        aria-label="static-filters"
        aria-haspopup=""
        onClick={() => setOpen(!open)}
      >
        <div className="flex">
          {filters.length > 0 && (
            <div style={{ backgroundColor: colors.secondary }} className={`rounded-full h-4 w-4 text-white text-xs -ml-[6px] mr-1 mt-[2px]`}>
              {filters.length}
            </div>
          )}
          <p className={`line-clamp-1 m-0`}>
            {pageTexts?.brand ?? ``}
          </p>
        </div>
        <DropDownArrows {...{ open }} className="text-neutral-600"/>
      </TWButton>
      {tablet && <PopperFilter {...{ open, anchorRef, handleClose }}>
        <ListMenu />
      </PopperFilter>}
      {!tablet && <FilterDrawer {...{ open, desk: false, mobile }}>
        <ListDrawer />
      </FilterDrawer>}
    </>
  )
}
