'use client'
/* eslint-disable no-extra-parens */
import { TWButton } from "@/components/tailwind/Button"
import { CatalogueContext } from "@/context"
import { types } from "@/types"
import { constructPath } from "@/utils/functions/catalogue"
import { constants } from "@/utils/global"
import { useRouter } from "next/navigation"
import queryString from "query-string"
import {
  useContext, useEffect, useId, useMemo, useRef, useState
} from "react"
import { useDebounce } from "use-debounce"
import { DropDownArrows } from "../../basket/subcomponent/components/subcomponents/DropDownArrows"
import { PopperFilter } from "../desktop"
import { FilterDrawer } from "../drawer"
import { useMediaQuerys } from "@/hooks/utils/useMediaQuerys"

export const PriceFilter = ({ ...props }) => {
  const router = useRouter()

  const { info, handlePathFilter, handleLoading } = useContext(CatalogueContext)
  const { pathFilter, cleanPath } = info
  const {
    hostname, filters, realMaxPrice
  } = props
  const { colors, mobile } = hostname
  const anchorRef = useRef()
  const idRef = useId()
  const [ open, setOpen ] = useState(false)
  const price = useMemo(() => [ constants.PRICE_RANGE.min, realMaxPrice ], [realMaxPrice])
  const [ value, setValue ] = useState([ constants.PRICE_RANGE.min, realMaxPrice ])
  const [ focMin, setFocMin ] = useState(false)
  const [ focMax, setFocMax ] = useState(false)
  const [ dbVal, setDbVal ] = useDebounce(value, 1500)
  const { tablet } = useMediaQuerys()

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleChangePath = async(event, newValue) => {
    !mobile && (await setValue(newValue))

    // const flgValue = price[0] === newValue[0] && price[1] === newValue[1]
    const flgValue = JSON.stringify(price) === JSON.stringify(newValue)
    const params = await queryString.parse(globalThis.window.location.search, {
      arrayFormat: `bracket-separator`,
      arrayFormatSeparator: `|`
    })
    const { r, ...tempParams } = params
    const finalParams = {
      ...params,
      r: newValue || price,
    }
    const obj = {
      ...constants.OBJ_FILTER,
      type: types.catalog.priceRange,
      parent: types.catalog.get,
      pathParams: [ `GET`, `r`, newValue ],
      price: {
        min: newValue[0],
        max: newValue[1],
      },
    }
    const newFilter =
      (await pathFilter.find(x => x.type === types.catalog.priceRange)) && flgValue ?
        await pathFilter.filter(x => x.type !== types.catalog.priceRange) :
        [ pathFilter.filter(x => x.type !== types.catalog.priceRange), obj ].flat()
    await handlePathFilter(newFilter)
    await handleLoading(true)
    await constructPath(
      flgValue ? tempParams : finalParams, newFilter, cleanPath, router
    )
  }

  useEffect(() => {
    mobile && dbVal && setValue(dbVal)
  }, [dbVal])

  useEffect(() => {
    if (mobile && (price[0] !== value[0] || price[1] !== value[1])) {
      handleChangePath({}, value)
    }
  }, [value])

  useEffect(() => {
    const params = queryString.parse(globalThis.window.location.search, {
      arrayFormat: `bracket-separator`,
      arrayFormatSeparator: `|`
    })
    params.r ? setValue([ parseInt(params.r[0], 10), parseInt(params.r[1], 10) ]) : setValue([ constants.PRICE_RANGE.min, realMaxPrice ])
  }, [realMaxPrice])

  useEffect(() => {
    const params = queryString.parse(globalThis.window.location.search, {
      arrayFormat: `bracket-separator`,
      arrayFormatSeparator: `|`
    })
    params.r && setValue([ parseInt(params.r[0], 10), parseInt(params.r[1], 10) ])
  }, [])

  const InputRange = () => (
    <div className="grid grid-cols-7 items-center mb-3">
      <div className="col-span-3">
        <input
          type="number"
          className="text-center w-full p-2"
          id={`input-filter`}
          min={constants.PRICE_RANGE.min}
          max={realMaxPrice}
          placeholder={`${value[0]?.toString()}€`}
          autoFocus={focMin}
          onFocus={() => setFocMin(true)}
          onBlur={() => setFocMin(false)}
          onChange={e => {
            if (mobile) {
              let nextValue = parseInt(e.target.value, 10)
              if (nextValue < constants.PRICE_RANGE.min) {
                nextValue = constants.PRICE_RANGE.min
              }
              const [ start, end ] = value
              if (nextValue > end) {
                return
              }
              setDbVal([ nextValue, end ])
            }
          }}
        />
      </div>
      <div className="col-span-1">
        <p className="text-center text-[14px] text-emerald-700">{`-`}</p>
      </div>
      <div className="col-span-3">
        <input
          type="number"
          className="text-center w-full p-2"
          id={`input-filter`}
          min={constants.PRICE_RANGE.min}
          max={realMaxPrice}
          placeholder={`${value[1]?.toString()}€`}
          autoFocus={focMax}
          onFocus={() => setFocMax(true)}
          onBlur={() => setFocMax(false)}
          onChange={e => {
            if (mobile) {
              let nextValue = parseInt(e.target.value, 10)
              if (nextValue > realMaxPrice) {
                nextValue = realMaxPrice
              }
              const [ start, end ] = value
              if (start > nextValue) {
                return
              }
              setDbVal([ start, nextValue ])
            }
          }}
          {...{ mobile: mobile.toString() }}
        />
      </div>
    </div>
  )
  const MenuItems = () => (
    <>
      {!mobile ? (
        <div className="p-4 w-[220px]">
          <InputRange />
          {/* <RangeSlider
            defaultValue={value}
            min={constants.PRICE_RANGE.min}
            max={realMaxPrice}
            onChangeCommitted={(newVal, e) => handleChangePath(e, newVal)} /> */}
        </div>
      ) : (
        <InputRange />
      )}
    </>
  )

  return (
    <>
      <TWButton
        {...{ colors }}
        id={idRef}
        ref={anchorRef}
        type="dropdown"
        bg={colors.lightGrey}
        className="w-[100%] border-0 tablet:border-[1px] tablet:pl-[12px] tablet:pr-[6px] tablet:py-[2px] tablet:min-w-28"
        border={colors.lightGrey3}
        aria-controls={open ? `split-filter-price` : undefined}
        aria-expanded={open ? `true` : undefined}
        aria-label="price-filters"
        aria-haspopup=""
        onClick={() => setOpen(!open)}
      >
        <div className="flex">
          {filters?.length > 0 && (
            <div style={{ backgroundColor: colors.secondary }} className={`rounded-full h-4 w-4 text-white text-xs -ml-[6px] mr-1 mt-[2px]`}>
              {filters.length}
            </div>
          )}
          <p className={`line-clamp-1 m-0`}>
            {/* {parse(web.catalogo.filters.text16)}&nbsp; */}
            {`Precio`}
          </p>
        </div>
        <DropDownArrows {...{ open }} className="text-neutral-600"/>
      </TWButton>
      {tablet && <PopperFilter {...{ open, anchorRef, handleClose }}>
        <MenuItems />
      </PopperFilter>}
      {!tablet && <FilterDrawer {...{ open, desk: false, mobile, width: `auto` }}>
        <MenuItems />
      </FilterDrawer>}
    </>
  )
}
